import axios from 'axios';
import { router } from '../../routes/router';
import { paginatedFetch } from '../../util/paginatedFetch';
import { guestDataFor } from '../../util/guest';
import { reservationFactory } from '../../util/reservation';
import dayjs from 'dayjs';

const state = {
  reservations: [],
  reservationsRequest: false,
  selectedReservation: null,
  reservationErrors: {},
  reservationRequest: false,
  reservationHistory: [],
  reservationHistoryRequest: false,
};

const getters = {};

const mutations = {
  setReservations: (state, payload) => {
    state.reservations = payload;
  },
  setReservationErrors: (state, payload) => {
    state.reservationErrors = payload;
  },
  selectReservation: (state, payload = {}) => {
    state.selectedReservation = { timestamp: new Date(), ...payload };
  },
  addContactToReservation: (state, payload) => {
    if (state.selectedReservation) {
      state.selectedReservation.guests.push(guestDataFor(payload));
    }
  },
  setReservationRequest: (state, payload) => {
    state.reservationRequest = payload;
  },
  setReservationsRequest: (state, payload) => {
    state.reservationsRequest = payload;
  },
  setReservationHistory: (state, payload) => {
    state.reservationHistory = payload;
  },
  setReservationHistoryRequest: (state, payload) => {
    state.reservationHistoryRequest = payload;
  },
};

const actions = {
  initialize: async ({ commit, rootState, state }, { query }) => {
    commit('setReservations', []);
    commit('setReservationsRequest', true);
    const user = rootState.authentication;

    const data = { query };
    await paginatedFetch(
      user.resources.queries.reservations.url,
      data,
      (results) => commit('setReservations', state.reservations.concat(results.map(reservationFactory))),
      () => commit('setReservationsRequest', false),
      1,
      {
        guest: {}
      }
    );
  },
  newReservation: ({ dispatch }, { startsAt, endsAt, roomId }) => {
    return dispatch('selectReservation', { guests: [], spendings: [], gross_amount: 0, status: 'pending', roomId, startsAt, endsAt });
  },
  selectReservation: ({ commit, rootGetters, rootState }, payload) => {
    const { company } = rootState.authentication;

    commit('selectReservation', null);
    if (payload.url) {
      commit('setReservationRequest', true);
      axios
        .get(payload.url)
        .then(response => commit('selectReservation', reservationFactory(response.data)))
        .catch(() => commit('addMessage', { message: 'messages.something_went_wrong', type: 'warning' }, { root: true }))
        .finally(() => commit('setReservationRequest', false));
    } else {
      let date = new Date();
      let roomId = rootGetters['authentication/getAllRooms'][0].id;
      if (router.currentRoute.name === 'calendar') {
        const { year, month, id } = router.currentRoute.params;
        date = dayjs().year(year).month(month - 1).date(1).toDate();
        roomId = rootGetters['authentication/getCalendarById'](parseInt(id)).rooms[0].id;
      }
      const startsAt = payload.startsAt || date;
      const endsAt = dayjs(startsAt).add(1, 'day').toDate();
      const reservationStart = company.config.reservation.start.split(':');
      const reservationEnd = company.config.reservation.end.split(':');
      startsAt.setHours(parseInt(reservationStart[0]));
      startsAt.setMinutes(parseInt(reservationStart[1]));
      endsAt.setHours(parseInt(reservationEnd[0]));
      endsAt.setMinutes(parseInt(reservationEnd[1]));

      const reservation = {
        starts_at: startsAt.toISOString(),
        ends_at: endsAt.toISOString(),
        room_id: payload.roomId || roomId,
      };
      setTimeout(() => commit('selectReservation', { ...reservation, ...payload, startsAt, endsAt }), 1);
    }
  },
  saveReservation: ({ commit, dispatch, rootGetters }, payload) => {
    const room = rootGetters['authentication/getRoomById'](payload.data.room_id);

    const url = payload.url ? payload.url : room.resources.reservations.url;
    const method = payload.url ? 'patch' : 'post';
    commit('setReservationRequest', true);
    return axios[method](url, payload.data)
      .then(response => {
        commit('setReservationErrors', []);
        dispatch('calendar/reinit', null, { root: true });
        commit('addMessage', { message: 'reservation.save_success', type: 'success' }, { root: true });
        setTimeout(() => commit('selectReservation', reservationFactory(response.data)), 1000);
      })
      .catch(response => commit('setReservationErrors', response.response.data.errors))
      .finally(() => commit('setReservationRequest', false));
  },
  deleteReservation: ({ commit, dispatch }, { url }) => {
    return axios.delete(url)
      .then(() => {
        commit('selectReservation', null);
        dispatch('calendar/reinit', null, { root: true });
        commit('addMessage', { message: 'reservation.delete_success', type: 'success' }, { root: true });
      });
  },
  loadReservationHistory: ({ commit, dispatch }, payload) => {
    commit('setReservationHistory', []);
    commit('setReservationHistoryRequest', true);
    return axios
      .get(payload.url)
      .then(response => commit('setReservationHistory', response.data.results))
      .catch(() => commit('addMessage', { message: 'messages.something_went_wrong', type: 'warning' }, { root: true }))
      .finally(() => commit('setReservationHistoryRequest', false));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
