import { spendingsFactory } from './spending';
import { getNights, newDate } from './dates';

// eslint-disable-next-line camelcase
const reservationFactory = ({ starts_at, ends_at, spendings, ...rest }) =>
  ({
    ...rest,
    nights: getNights(starts_at, ends_at),
    startsAt: newDate(starts_at),
    endsAt: newDate(ends_at),
    spendings: spendingsFactory(spendings || []),
    itemType: 'reservation',
  });

export {
  reservationFactory
};
