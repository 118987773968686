<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          :label="$t('event.search')"
          filled
          icon="mdi-magnify"
          v-model="filters.search"
          prepend-icon="mdi-text-search"
        ></v-text-field>
      </v-col>
      <v-col>
        <date-picker
          :label="$t('event.from')"
          :placeholder="$t('event.from')"
          filled
          icon="mdi-calendar"
          v-model="filters.from_date"
        ></date-picker>
      </v-col>
      <v-col>
        <date-picker
          :label="$t('event.until')"
          :placeholder="$t('event.until')"
          filled
          icon="mdi-calendar"
          v-model="filters.to_date"
        ></date-picker>
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </v-container>
</template>

<script>
  import DatePicker from '@/components/shared/pickers/DatePicker';

  let closeTimer = null;

  export default {
    components: {
      DatePicker
    },
    props: {
      value: {
        type: Object,
      },
    },
    data() {
      return {
        filters: this.value,
      };
    },
    computed: {
      rooms() {
        return this.$store.getters['authentication/getRoomsWithHeaders'].map(room => ({ ...room }));
      },
      calendars() {
        return this.$store.state.authentication.company.calendars.map(calendar => ({ text: calendar.title, value: calendar.id }));
      },
    },
    methods: {
      closeDebounce() {
        if (closeTimer) {
          clearTimeout(closeTimer);
        }
        closeTimer = setTimeout(() => this.$refs.select.blur(), 1000);
      },
    },
  };
</script>
