<template>
  <v-data-table
    :disable-pagination="true"
    :headers="headers"
    :hide-default-footer="true"
    :items="events"
    @click:row="rowClick"
    calculate-widths
    class="fill-height"
    fixed-header
  >
    <template v-slot:top>
      <event-filters
        v-model="filters"
      ></event-filters>
    </template>
    <template v-slot:item.startsAt="{ item }">
      {{ dayjs(item.startsAt).format('LLLL') }}
    </template>
    <template v-slot:item.room_id="{ item }">
      {{ getRoom(item.room_id).title }}
    </template>
    <template v-slot:item.guests="{ item }">
      {{ item.guests?.length ? item.guests.map(guest => guest.name).join(', ') : '-' }}
    </template>
  </v-data-table>
</template>

<script>
  import dayjs from 'dayjs';

  import isBetween from 'dayjs/plugin/isBetween';
  import EventFilters from './EventFilters';

  dayjs.extend(isBetween);

  let searchTimer = null;

  export default {
    components: {
      EventFilters
    },
    data() {
      return {
        filters: {
          statuses: ['confirmed'],
          room_id: null,
          from_date: dayjs().format('YYYY-MM-DD'),
          to_date: dayjs().add(1, 'week').format('YYYY-MM-DD'),
          search: null,
        },
      };
    },
    computed: {
      headers() {
        return [
          { text: this.$t('event.title'), value: 'title', sortable: true },
          { text: this.$t('event.starts_at'), value: 'startsAt', sortable: true, width: '25%' },
          {
            text: this.$t('event.note'),
            align: 'start',
            sortable: true,
            value: 'note',
          },
          { text: this.$t('event.guests'), value: 'guests', sortable: true }
        ];
      },
      events() {
        return this.$store.state.events.events;
      },
    },
    methods: {
      dayjs,
      rowClick(item) {
        this.$store.dispatch('events/selectEvent', item);
      },
    },
    watch: {
      filters: {
        handler() {
          clearTimeout(searchTimer);
          searchTimer = setTimeout(() => {
            this.$store.dispatch('events/initialize', { query: this.filters });
          }, 1000);
        },
        deep: true,
      },
    },
    mounted() {
      this.$store.dispatch('events/initialize', { query: this.filters });
    },
  };
</script>
