<template>
  <v-container fluid>
    <v-icon>mdi-set-center</v-icon>
    <div class="items rounded-t">
      <label class="v-label v-label--active theme--light">{{label}}</label>
      <v-list v-if="items && items.length">
        <template
          v-if="type==='event'"
        >
          <event-list-item
            :item="item"
            :key="item.id"
            date-format="long"
            v-for="item in items"
          ></event-list-item>
        </template>
        <template
          v-if="type==='reservation'"
        >
          <reservation-list-item
            :item="item"
            :key="item.id"
            date-format="long"
            v-for="item in items"
          ></reservation-list-item>
        </template>
      </v-list>
      <div class="body-2 text-center pa-1" v-else v-t="'contact.no_items'"></div>
      <div class="text-center pa-1" v-if="person?.url">
        <v-btn @click="newItem" class="mx-auto">{{$t('contact.new_item')}}</v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
  import EventListItem from '../shared/EventListItem';
  import ReservationListItem from '../shared/ReservationListItem';
  import { guestDataFor } from '../../util/guest';

  const toTitleCase = s => s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();
  export default {
    components: {
      EventListItem,
      ReservationListItem,
    },
    props: {
      items: {
        type: Array,
      },
      person: {
        type: Object,
      },
      type: {
        type: String,
      },
    },
    computed: {
      label() {
        return this.$t(`contact.${this.type}s`);
      },
    },
    methods: {
      selectItem(item) {
        this.$store.dispatch(`${this.type}s/select${toTitleCase(this.type)}`, item);
      },
      newItem() {
        const item = { guests: [guestDataFor(this.person)] };
        this.selectItem(item);
      },
    },
  };
</script>

<style lang="scss" scoped>
.container {
  padding: 0 0 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;

  .v-icon {
    margin-right: 9px;
    margin-top: 17px;
  }

  .items {
    background: rgba(0, 0, 0, .06);
    width: 100%;
    border-bottom: 1px solid currentColor;

    label {
      transform: translateY(-18px) scale(.75);
      font-size: 12px;
      margin-left: 12px;
      margin-top: 6px;
    }

    .v-list-item {
      width: 100%;
    }

    .v-list {
      background: rgba(0, 0, 0, 0);
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 0;
    }
  }
}
</style>
