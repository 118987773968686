<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-container fluid>
        <v-row justify="center">
          <v-col class="pl-0 align-center d-flex">
            <v-icon>mdi-history</v-icon>
            <span v-t="'reservation.history'"></span>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card
        class="ma-1"
        max-width="600"
      >
        <v-card-text class="py-0">
          <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              v-for="(item, index) in $store.state.reservations.reservationHistory.map(formatItem)"
              small
              fill-dot
              :icon="item.icon"
              :key="index"
              :color="reservationColor({ status: item.change })"
            >
              <strong>{{ item.created_at }}</strong>
              &nbsp;
              <strong>{{ item.status}}</strong>
              <div class="text-caption">({{ item.email}})</div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import reservationMixin from '../../mixins/reservations';
  import dayjs from 'dayjs';

  export default {
    mixins: [reservationMixin],
    props: {
      reservation: {
        type: Object,
      },
      active: {
        type: Boolean,
      }
    },
    methods: {
      formatItem(item) {
        return {
          ...item,
          created_at: dayjs(item.created_at).format('YYYY-MM-DD HH:mm'),
          status: this.$t(`reservation.statuses.${item.change}`),
          email: item.email,
          icon: item.event === 'create' ? 'mdi-plus' : 'mdi-pencil',
        };
      },
    },
    watch: {
      active(value) {
        if (value) {
          this.$store.dispatch('reservations/loadReservationHistory', { url: this.reservation?.history?.url });
        }
      },
    }
  };
</script>
