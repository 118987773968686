<template>
  <v-data-table
    :disable-pagination="true"
    disable-sort
    :headers="headers"
    :hide-default-footer="true"
    :items="people"
    @click:row="rowClick"
    calculate-widths
    class="fill-height"
    fixed-header
    sort-by="name"
  >
    <template v-slot:top>
      <person-filters
        v-model="filters"
      ></person-filters>
    </template>
  </v-data-table>
</template>

<script>
  import PersonFilters from '@/components/contacts/PersonFilters';

  let searchTimer = null;
  export default {
    components: { PersonFilters },
    data: () => ({
      filters: {
        search: '',
      },
    }),
    computed: {
      headers() {
        return [
          {
            text: this.$t('contact.name'),
            align: 'start',
            sortable: true,
            value: 'name',
            width: '25%',
          },
          { text: this.$t('contact.phone'), value: 'phones' },
          { text: this.$t('contact.email'), value: 'emails' },
          { text: this.$t('contact.address'), value: 'address' }
        ];
      },
      people() {
        return this.$store.state.contacts.people;
      },
    },
    methods: {
      rowClick(item) {
        this.$store.dispatch('contacts/selectPerson', item);
      },
      advancedSearch(value, search, item) {
        return value !== null &&
          search !== null &&
          typeof value === 'string' &&
          value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase()
            .indexOf(search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()) > -1;
      },
    },
    watch: {
      filters: {
        handler() {
          clearTimeout(searchTimer);
          searchTimer = setTimeout(() => {
            this.$store.dispatch('contacts/initialize', { query: this.filters });
          }, 1000);
        },
        deep: true,
      },
    },
    mounted() {
      this.$store.dispatch('contacts/initialize', { query: this.filters });
    },
  };
</script>
