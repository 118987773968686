<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-text-field
          :label="$t('reservation.search')"
          filled
          icon="mdi-magnify"
          v-model="filters.search"
          prepend-icon="mdi-text-search"
        ></v-text-field>
      </v-col>
      <v-col>
        <date-picker
          :label="$t('reservation.from')"
          :placeholder="$t('reservation.from')"
          filled
          icon="mdi-calendar"
          v-model="filters.from_date"
        ></date-picker>
      </v-col>
      <v-col>
        <date-picker
          :label="$t('reservation.until')"
          :placeholder="$t('reservation.until')"
          filled
          icon="mdi-calendar"
          v-model="filters.to_date"
        ></date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <translated-select
          prepend-icon="mdi-list-status"
          :label="$t('reservation.status')"
          filled
          prefix="reservation.statuses"
          :items="$store.state.data.reservationStatuses"
          multiple
          v-model="filters.statuses"
        ></translated-select>
      </v-col>
      <v-col>
        <v-select
          :items="rooms"
          :label="$t('reservation.room')"
          filled
          multiple
          @change="closeDebounce"
          v-model="filters.room_id"
          ref="select"
          @blur="$refs.select.blur()"
        >
          <template v-slot:prepend>
            <v-icon>mdi-bed</v-icon>
          </template>
          <template v-slot:append>
            <v-icon @click="filters.room_id = null; $refs.select.blur()">mdi-close</v-icon>
          </template>
        </v-select>
      </v-col>
      <v-col>
        <v-select
          :items="calendars"
          :label="$t('settings.rooms.calendar')"
          filled
          multiple
          @change="closeDebounce"
          v-model="filters.calendar_id"
          ref="calendarSelect"
          @blur="$refs.calendarSelect.blur()"
        >
          <template v-slot:prepend>
            <v-icon>mdi-bed</v-icon>
          </template>
          <template v-slot:append>
            <v-icon @click="filters.calendar_id = null; $refs.calendarSelect.blur()">mdi-close</v-icon>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import DatePicker from '@/components/shared/pickers/DatePicker';
  import TranslatedSelect from '@/components/shared/TranslatedSelect';

  let closeTimer = null;

  export default {
    components: {
      TranslatedSelect,
      DatePicker
    },
    props: {
      value: {
        type: Object,
      },
    },
    data() {
      return {
        filters: this.value,
      };
    },
    computed: {
      rooms() {
        return this.$store.getters['authentication/getRoomsWithHeaders'].map(room => ({ ...room }));
      },
      calendars() {
        return this.$store.state.authentication.company.calendars.map(calendar => ({ text: calendar.title, value: calendar.id }));
      },
    },
    methods: {
      closeDebounce() {
        if (closeTimer) {
          clearTimeout(closeTimer);
        }
        closeTimer = setTimeout(() => this.$refs.select.blur(), 1000);
      },
    },
  };
</script>
