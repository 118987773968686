<template>
  <dialog-container
    :close="reallyClose"
    v-model="open"
    :item="accommodation"
    :title="title"
    :dirty="dirty"
    type="accommodation"
    ref="dialog"
    klass="blue"
  >
    <template v-slot:body>
      <v-container fluid class="px-0">
        <v-row no-gutters>
          <v-col
            cols="12"
            md="12"
            lg="6"
          >
            <v-text-field
              :error-messages="accommodationErrors.title"
              :label="$t('settings.accommodation.title')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-subtitles"
              v-model="title"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <v-text-field
              :error-messages="accommodationErrors.city"
              :label="$t('settings.accommodation.city')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-subtitles"
              v-model="city"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <v-text-field
              :error-messages="accommodationErrors.zipcode"
              :label="$t('settings.accommodation.zipcode')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-subtitles"
              v-model="zipcode"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <v-text-field
              :error-messages="accommodationErrors.ntak_reg_number"
              :label="$t('settings.accommodation.ntak_reg_number')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-subtitles"
              v-model="ntakRegNumber"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <v-text-field
              :error-messages="accommodationErrors.ntak_id"
              :label="$t('settings.accommodation.ntak_id')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-subtitles"
              v-model="ntakId"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <certificate-table
              :certificates="certificates"
            ></certificate-table>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="generateCertificates"
        color="green darken-1"
        text
        v-if="!readonly"
        v-t="'settings.accommodation.pair_creation'"
        :disabled="accommodationRequest || !accommodation?.ntak_id || !accommodation?.ntak_reg_number || dirty"
      ></v-btn>
      <v-btn
        @click="save"
        color="green darken-1"
        text
        v-if="!readonly"
        v-t="'settings.accommodation.save'"
        :disabled="accommodationRequest || !dirty"
      ></v-btn>
      <v-btn
        @click="remove"
        color="red darken-1"
        text
        v-if="accommodation?.url && !readonly"
        v-t="'settings.accommodation.delete'"
      ></v-btn>
      <v-btn
        @click="$refs.dialog && $refs.dialog.dialogClose()"
        color="yellow darken-1"
        text
        v-t="'settings.accommodation.close'"
      ></v-btn>
    </template>
  </dialog-container>
</template>

<script>
  import DialogContainer from '../../shared/DialogContainer';
  import CertificateTable from './CertificateTable';

  export default {
    components: {
      CertificateTable,
      DialogContainer,
    },
    data() {
      return {
        open: false,
        title: '',
        city: '',
        zipcode: '',
        ntakRegNumber: '',
        ntakId: '',
      };
    },
    computed: {
      dirty() {
        const accommodation = this.accommodation || {};
        return !(this.title === accommodation.title &&
          this.city === accommodation.city &&
          this.zipcode === accommodation.zipcode &&
          this.ntakId === accommodation.ntak_id &&
          this.ntakRegNumber === accommodation.ntak_reg_number);
      },
      accommodation() {
        return this.$store.state.accommodations.accommodation;
      },
      accommodationRequest() {
        return this.$store.state.accommodations.accommodationRequest;
      },
      accommodationErrors() {
        return this.$store.state.accommodations.accommodationErrors;
      },
      readonly() {
        if (!this.accommodation) {
          return;
        }
        return this.accommodation.url && (this.accommodation.auth || []).indexOf('update') === -1;
      },
      certificates() {
        return [this.accommodation?.signing_certificate, this.accommodation?.authentication_certificate].filter(Boolean);
      },
    },
    methods: {
      reallyClose() {
        this.$store.commit('accommodations/setAccommodationErrors', []);
        this.open = false;
      },
      async generateCertificates() {
        const response = await this.$confirm(this.$t('settings.accommodation.pair_creation_confirm'));
        if (response) {
          this.$store.dispatch('certificates/createNewPair', { accommodation: this.accommodation });
        }
      },
      save() {
        this.$store.dispatch('accommodations/saveAccommodation', {
          url: this.accommodation.url,
          data: {
            title: this.title,
            city: this.city,
            zipcode: this.zipcode,
            ntak_reg_number: this.ntakRegNumber,
            ntak_id: this.ntakId,
          },
        });
      },
      remove() {
        this.$confirm(`${this.$t('settings.accommodation.confirm_delete')}<br/>${this.title}`)
          .then(response => {
            if (response) {
              this.$store.dispatch('accommodations/deleteAccommodation', {
                url: this.accommodation.url,
              });
            }
          });
      },
    },
    watch: {
      accommodation() {
        if (!this.accommodation) {
          this.open = false;
          return;
        }
        this.open = true;
        this.title = this.accommodation.title;
        this.city = this.accommodation.city;
        this.zipcode = this.accommodation.zipcode;
        this.ntakId = this.accommodation.ntak_id;
        this.ntakRegNumber = this.accommodation.ntak_reg_number;
      }
    },
  };
</script>
