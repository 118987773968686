<template>
  <dialog-container
    :close="reallyClose"
    v-model="open"
    :item="order"
    title=""
    :dirty="dirty"
    type="order"
    ref="dialog"
    klass="blue"
  >
    <template v-slot:body>
      <v-container fluid class="px-0">
        <v-row no-gutters>
          <v-col cols="12">
            <datetime-picker
              :disabled="readonly"
              :label="$t('order.occurs_at')"
              :error-messages="orderErrors.occurs_at"
              prepend-icon="mdi-orders-clock"
              v-model="occursAt"
            ></datetime-picker>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-textarea
              :error-messages="orderErrors.note"
              :label="$t('order.note')"
              :readonly="readonly"
              auto-grow
              filled
              prepend-icon="mdi-text"
              v-model="note"
            ></v-textarea>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <person-autocomplete
              :label="$t('order.person')"
              :existing="!!order?.url"
              type="Order"
              :readonly="readonly"
              v-model="person"
              :errors="orderErrors"
            ></person-autocomplete>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-icon>mdi-set-center</v-icon>
            <v-list class="reservations">
              <v-subheader>
                {{ $t('menu.reservations')}}
                <v-spacer></v-spacer>
                <v-btn>{{ $t('reservation.new')}}</v-btn>
              </v-subheader>
              <reservation-list-item
                v-for="reservation in filteredReservations"
                :key="reservation.id"
                :item="reservation"
                >
                <template v-slot:actions>
                  <v-btn @click.stop.prevent="removeReservation(reservation)" text v-if="!readonly">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </reservation-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="save"
        color="green darken-1"
        text
        v-if="!readonly"
        v-t="'order.save'"
        :disabled="orderRequest || !dirty && false"
      ></v-btn>
      <v-btn
        @click="remove"
        color="red darken-1"
        text
        v-if="order?.url && !readonly"
        v-t="'order.delete'"
      ></v-btn>
      <v-btn
        @click="$refs.dialog && $refs.dialog.dialogClose()"
        color="yellow darken-1"
        text
        v-t="'order.close'"
      ></v-btn>
    </template>
  </dialog-container>
</template>

<script>
  import DialogContainer from '../shared/DialogContainer';
  import DatetimePicker from '../shared/pickers/DatetimePicker';
  // import { compareDates } from '../../util/comparators';
  import PersonAutocomplete from '@/components/orders/PersonAutocomplete';
  import ReservationListItem from '@/components/shared/ReservationListItem.vue';

  export default {
    components: {
      ReservationListItem,
      PersonAutocomplete,
      DialogContainer,
      DatetimePicker,
    },
    data() {
      return {
        open: false,
        note: '',
        occursAt: new Date(),
        person: null,
        reservations: []
      };
    },
    computed: {
      dirty() {
        return false;
        // const order = this.order || {};
        // return !(this.title === order.title &&
        //   compareDates(this.occursAt, order?.occursAt) &&
        //   this.note === order.note);
      },
      order() {
        return this.$store.state.orders.selectedOrder;
      },
      orderErrors() {
        return this.$store.state.orders.orderErrors;
      },
      orderRequest() {
        return this.$store.state.orders.orderRequest;
      },
      readonly() {
        if (!this.order) {
          return;
        }
        return this.order.url && (this.order.auth || []).indexOf('update') === -1;
      },
      filteredReservations() {
        return this.reservations.filter(r => !r._destroy);
      }
    },
    watch: {
      order() {
        if (!this.order) {
          this.open = false;
          return;
        }
        this.open = true;
        this.note = this.order.note;
        this.occursAt = this.order.occursAt;
        this.title = this.order.title;
        this.person = { ...this.order.person };
        this.reservations = [...this.order.reservations];
      }
    },
    methods: {
      reallyClose() {
        this.$store.commit('orders/setOrderErrors', []);
        this.open = false;
      },
      save() {
        this.$store.dispatch('orders/saveOrder', {
          url: this.order.url,
          data: {
            occurs_at: this.occursAt,
            title: this.title,
            note: this.note,
            person_id: this.person?.person_id,
            reservations_attributes: this.reservations.map(r => ({
              id: r.id,
              _destroy: r._destroy,
            })),
          },
        });
      },
      remove() {
        this.$confirm(`${this.$t('order.confirm_delete')}<br/>${this.title}`)
          .then(response => {
            if (response) {
              this.$store.dispatch('orders/deleteOrder', {
                url: this.order.url,
              }).then(() => {
                this.open = false;
              });
            }
          });
      },
      removeReservation(reservation) {
        const index = this.reservations.findIndex(r => r.id === reservation.id);
        if (index === -1) {
          return;
        }
        this.reservations.splice(index, 1, { ...reservation, _destroy: true });
      },
    },
  };
</script>

<style lang="scss">

.reservations {
  background: rgba(0, 0, 0, .06);
  display: inline-grid;
  border-bottom: 1px solid black;
  padding: 12px;
  width: 100%;
}

</style>
