<template>
  <v-card-title
    class="text-md-h6 text-subtitle-2"
    :class="klass"
    style="cursor: pointer"
  >
    <span>
      {{ dialogTitle }}
        &nbsp;-&nbsp;
      {{ title }}
      {{ dirty ? ' *' : ''}}
    </span>
    <template v-if="!$vuetify.breakpoint.mobile">
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="toggle"
      >
        <v-icon>mdi-swap-vertical-bold</v-icon>
      </v-btn>
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-card-title>
</template>

<script>
  export default {
    props: {
      title: {
        type: String
      },
      type: {
        type: String,
      },
      toggle: {
        type: Function
      },
      close: {
        type: Function
      },
      item: {
        type: Object
      },
      klass: {
        type: String
      },
      dirty: {
        type: Boolean
      }
    },
    computed: {
      dialogTitle() {
        if (!this.item) {
          return;
        }
        const postfix = this.item.url ? 'edit' : 'new';
        const prefix = {
          person: 'contact',
          event: 'event',
          order: 'order',
          reservation: 'reservation',
          inventory: 'inventory',
          user: 'settings.users',
          calendar: 'settings.calendars',
          room: 'settings.rooms',
          accommodation: 'settings.accommodation'
        }[this.type];

        return this.$t(`${prefix}.${postfix}`);
      },
    },
  };
</script>
