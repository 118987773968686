<template>
  <v-list-item
    @click="selectEvent"
    two-line
  >
    <v-list-item-content>
      <v-list-item-title class="subtitle-2">{{ $d(item.startsAt, dateFormat ? dateFormat : 'hoursAndMinutes') }}
      </v-list-item-title>
      <v-list-item-subtitle> {{ item.title}}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    props: ['item', 'dateFormat'],
    methods: {
      selectEvent() {
        this.$emit('click', this.item);
        this.$store.dispatch('events/selectEvent', this.item);
      },
    },

  };
</script>
