<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <navigational-buttons
          :month="month"
          :year="year"
          type="calendar"
        ></navigational-buttons>
      </v-col>
      <v-col cols="12" class="table-container">
        <table
          :class="{big: $vuetify.breakpoint.mdAndUp}"
          class="v-calendar"
        >
          <calendar-header
            :days="days"
            with-ref
            ref="header"
          ></calendar-header>
          <event-row :days="days"></event-row>
          <tr class="room" :key="room.id" v-for="room in rooms">
            <td
              class="pa-1 subtitle-2 text-right room-title"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >{{ room.title }}</span>
                </template>
                <span>{{ room.title }}</span>
              </v-tooltip>
            </td>
            <td class="pa-1 room-icon text-center">
              <v-btn
                icon
                x-small
                :to="`/rooms/${room.id}/${year}/${month}`"
              >
                <v-icon small>mdi-{{room.icon}}</v-icon>
              </v-btn>
            </td>
            <td :class="day.day" :key="day.title" v-for="(day,i) in days" @click="cellClick(room, day.date)">
              <template
                v-if="i===0"
              >
                <reservation-chip
                  :key="reservation.id"
                  :base="base"
                  :month="month"
                  :reservation="reservation"
                  v-for="reservation in filterReservations(room)"
                ></reservation-chip>
              </template>
            </td>
            <td class="pa-1 room-icon text-center">
              <v-icon small>mdi-{{room.icon}}</v-icon>
            </td>
            <td
              class="pa-1 subtitle-2 text-left room-title"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >{{ room.title }}</span>
                </template>
                <span>{{ room.title }}</span>
              </v-tooltip>
            </td>
          </tr>
          <calendar-header
            :days="days"
          ></calendar-header>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import NavigationalButtons from '../shared/navigation/NavigationalButtons';
  import ReservationChip from './ReservationChip';
  import EventRow from './EventRow';
  import CalendarHeader from './CalendarHeader';
  import { daysOfMonth } from '../../util/dates';

  export default {
    data() {
      const { year, month } = this.$route.params;
      return {
        year: parseInt(year),
        month: parseInt(month),
        base: [],
      };
    },
    components: {
      NavigationalButtons,
      ReservationChip,
      EventRow,
      CalendarHeader,
    },
    computed: {
      reservations() {
        return this.$store.state.reservations.reservations;
      },
      rooms() {
        const { id } = this.$route.params;
        return this.$store.getters['authentication/getRoomsFor'](parseInt(id));
      },
      days() {
        return daysOfMonth(this.year, this.month, this.holidays, this.locale);
      },
      holidays() {
        return this.$store.state.authentication.company.holidays.map(holiday => ({
          ...holiday,
          isAt: new Date(holiday.is_at)
        }));
      },
      locale() {
        return this.$store.state.authentication.company.locale;
      }
    },
    methods: {
      filterReservations(room) {
        return this.reservations.filter((reservation) => reservation.room_id === room.id);
      },
      handleResize() {
        this.base = [...this.$refs.header.$refs.dayCell, this.$refs.header.$refs.lastCell].map(cell => cell.getBoundingClientRect().left);
      },
      cellClick(room, startsAt) {
        const endsAt = new Date(startsAt.getTime());
        this.$store.dispatch('reservations/newReservation', { startsAt, endsAt, roomId: room.id });
      },
    },
    watch: {
      '$route'(to, from) {
        const { year, month } = to.params;
        this.year = parseInt(year);
        this.month = parseInt(month);
        this.$store.dispatch('calendar/initialize', this.$route.params)
          .then(this.handleResize);
      },
    },
    mounted() {
      this.handleResize();
      this.$store.dispatch('calendar/initialize', this.$route.params);
    },
    created() {
      window.addEventListener('resize', this.handleResize);
      setTimeout(this.handleResize, 1000);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
  };
</script>

<style lang="scss">
  .table-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    table {
      min-width: 955px;
      width: 99%;
      border-collapse: collapse;
      border-spacing: 0;
      border: none;
      color: #3c4043;

      &.big {
        td {
          width: 35px;

          &.room-title {
            min-width: 35px;
          }
        }
      }
    }

    tr.room {
      &:hover {
        td {
          background-color: #eeeeee !important;
        }
      }
      td {
        cursor: cell;
      }
    }

    .header {
      td {
        text-align: center;
      }
    }

    td {
      position: relative;
      width: 2.8571428571%;
      padding: 0;

      border: 1px solid lightgrey;
      background-clip: padding-box;

      &.weekend {
        background-color: #f5f5f5;
      }

      &.today {
        background-color: #BBDEFB;
      }

      &.empty {
        border: none;
      }

      &.room-title {
        border-left: none;
        border-right: none;
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: inherit !important;
      }

      &.room-icon {
        border-left: none;
        border-right: none;
        cursor: inherit !important;
      }

      &.top {
        border-top: none;
      }

      &.bottom {
        border-bottom: none;
      }

      &.event {
        text-align: center;
      }
    }
  }
</style>
