import Vue from 'vue';
import Vuex from 'vuex';
import authentication from './modules/authentication';
import calendar from './modules/calendar';
import orders from './modules/orders';
import contacts from './modules/contacts';
import dashboard from './modules/dashboard';
import settings from './modules/settings';
import search from './modules/search';
import statistics from './modules/statistics';
import inventory from './modules/inventory';
import data from './modules/data';
import room from './modules/room';
import rooms from './modules/rooms';
import accommodations from './modules/accommodations';
import certificates from './modules/certificates';
import messages from './modules/messages';
import axios from 'axios';
import reservations from '@/store/modules/reservations';
import events from '@/store/modules/events';

Vue.use(Vuex);
const state = {
  message: null,
  countries: [],
};

const mutations = {
  addMessage: (state, payload) => {
    state.message = payload;
  },
  setCountries: (state, payload) => {
    state.countries = payload;
  },
};

const actions = {
  initializeCountries: ({ commit, state }) => {
    if (state.countries.length !== 0) {
      return Promise.resolve();
    }

    return axios.get('/api/countries').then(({ data }) => commit('setCountries', data));
  }
};

export const store = new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    accommodations,
    reservations,
    events,
    authentication,
    calendar,
    certificates,
    contacts,
    orders,
    dashboard,
    settings,
    search,
    data,
    room,
    rooms,
    statistics,
    messages,
    inventory
  }
});
