import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/hu';

dayjs.extend(localizedFormat);
dayjs.extend(duration);

// eslint-disable-next-line no-undef
dayjs.locale(i18nData.locale);

const newDate = (dateStr) => dateStr ? new Date(dateStr) : null;

const getNights = (startsAt, endsAt) => dayjs.duration(dayjs(endsAt).diff(startsAt)).days() + 1;

const daysOfMonth = (year, month, holidays, locale) => {
  const today = new Date();

  const length = new Date(year, month, 0).getDate();
  return Array.from({ length: length }, (x, i) => {
    const date = new Date(year, month - 1, i + 1);
    const day = dayjs(today).isSame(date, 'day') ? 'today' : isHoliday(date, holidays);

    return {
      date,
      day,
      title: title(date, locale),
      number: number(date, locale)
    };
  });
};

const isHoliday = (date, holidays) => {
  switch (holidays.find(holiday => dayjs(holiday.isAt).isSame(date, 'day'))?.holiday) {
    case true:
      return 'weekend';
    case false:
      return 'weekday';
    default:
      return date.getDay() % 6 === 0 ? 'weekend' : 'weekday';
  }
};

const title = (date, locale) => date.toLocaleDateString(locale, { day: 'numeric' });
const number = (date, locale) => date.toLocaleDateString(locale, { weekday: 'narrow' });

export { newDate, daysOfMonth, getNights };
