import axios from 'axios';
import { paginatedFetch } from '~/util/paginatedFetch';
import { personFactory } from '~/util/person';
import { orderFactory } from '@/util/order';
import { guestDataFor } from '~/util/guest';

const state = {
  orders: [],
  selectedOrder: null,
  orderErrors: {},
  orderRequest: false,
  ordersRequest: false,
  enabled: true,
};

const getters = {};

const mutations = {
  setOrders: (state, payload) => {
    state.orders = payload;
  },
  setOrderRequest: (state, payload) => {
    state.personRequest = payload;
  },
  setOrdersRequest: (state, payload) => {
    state.ordersRequest = payload;
  },
  selectOrder: (state, payload) => {
    state.selectedOrder = payload;
  },
  setOrderErrors: (state, payload) => {
    state.personErrors = payload;
  },
  enable: (state, payload) => {
    state.enabled = true;
  },
  disable: (state, payload) => {
    state.enabled = false;
  },
  addContactToOrder: (state, payload) => {
    if (state.selectedOrder) {
      state.selectedOrder = { ...state.selectedOrder, person: guestDataFor(payload) };
    }
  },
};

const actions = {
  initialize: ({ commit, rootState, state }) => {
    commit('setOrders', []);
    commit('setOrdersRequest', true);
    const user = rootState.authentication;
    paginatedFetch(
      user.resources.queries.orders.url,
      { query: { search: '' } },
      (results) => commit('setOrders', state.orders.concat(results.map(orderFactory))),
      () => commit('setOrdersRequest', false)
    );
  },
  selectOrder: ({ commit }, payload) => {
    commit('selectOrder', null);
    if (payload.url) {
      commit('setOrderRequest', true);
      axios
        .get(payload.url)
        .then(response => commit('selectOrder', orderFactory(response.data)))
        .catch(() => commit('addMessage', { message: 'messages.something_went_wrong', type: 'warning' }, { root: true }))
        .finally(() => commit('setOrderRequest', false));
    } else {
      commit('selectOrder', payload);
    }
  },
  saveOrder: ({ commit, dispatch, rootState }, { url, data, addToReservation, addToEvent }) => {
    const user = rootState.authentication;
    const method = url ? 'patch' : 'post';
    commit('disable');

    commit('setOrderRequest', true);
    return axios[method](url || user.company.resources.orders.url, data)
      .then(response => {
        commit('setOrderErrors', []);
        dispatch('initialize');
        commit('addMessage', { message: 'order.save_success', type: 'success' }, { root: true });
        if (addToEvent) {
          commit('events/addContactToEvent', response.data, { root: true });
        }
        if (addToReservation) {
          commit('reservations/addContactToReservation', response.data, { root: true });
        }
        commit('selectOrder', orderFactory(response.data));
      })
      .catch(response => commit('setOrderErrors', response.response.data.errors))
      .finally(() => {
        commit('setOrderRequest', false);
        return commit('enable');
      });
  },
  deleteOrder: ({ commit, dispatch }, { url }) => {
    return axios.delete(url)
      .then(() => {
        commit('selectOrder', null);
        dispatch('initialize');
        commit('addMessage', { message: 'order.delete_success', type: 'success' }, { root: true });
      });
  },
  newOrder: ({ dispatch }, { occursAt }) => {
    return dispatch('selectOrder', { note: '', reservations: [], person: { name: '' }, occursAt });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
