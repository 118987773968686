<template>
  <v-data-table
    :disable-pagination="true"
    :headers="headers"
    :hide-default-footer="true"
    :items="reservations"
    :item-class="itemBackgroundColor"
    @click:row="rowClick"
    calculate-widths
    class="fill-height"
    fixed-header
  >
    <template v-slot:top>
      <reservation-filters
        v-model="filters"
      ></reservation-filters>
    </template>
    <template v-slot:item.status="{ item }">
      <v-icon :color="reservationStyle(item)">mdi-checkbox-blank-circle</v-icon>
      {{ $t(`reservation.statuses.${item.status}`) }}
    </template>
    <template v-slot:item.startsAt="{ item }">
      {{ dayjs(item.startsAt).format('LLLL') }}
    </template>
    <template v-slot:item.endsAt="{ item }">
      {{ dayjs(item.endsAt).format('LLLL') }}
    </template>
    <template v-slot:item.room_id="{ item }">
      {{ getRoom(item.room_id).title }}
    </template>
    <template v-slot:item.guests="{ item }">
      {{ item.guests?.length ? item.guests.map(guest => guest.name).join(', ') : '-' }}
    </template>
  </v-data-table>
</template>

<script>
  import dayjs from 'dayjs';
  import reservationMixin from '../../mixins/reservations';

  import isBetween from 'dayjs/plugin/isBetween';
  import ReservationFilters from '@/components/reservations/ReservationFilters';

  dayjs.extend(isBetween);

  let searchTimer = null;

  export default {
    mixins: [reservationMixin],
    components: {
      ReservationFilters
    },
    data() {
      return {
        filters: {
          statuses: ['confirmed'],
          room_id: null,
          from_date: dayjs().format('YYYY-MM-DD'),
          to_date: dayjs().add(1, 'week').format('YYYY-MM-DD'),
          search: null,
        },
      };
    },
    computed: {
      headers() {
        return [
          { text: this.$t('reservation.status'), value: 'status', sortable: true },
          { text: this.$t('reservation.starts_at'), value: 'startsAt', sortable: true },
          { text: this.$t('reservation.ends_at'), value: 'endsAt', sortable: true },
          {
            text: this.$t('reservation.room'),
            value: 'room_id',
            sortable: true,
            sort: this.sortRoom
          },
          { text: this.$t('reservation.nights'), value: 'nights', sortable: true },
          { text: this.$t('reservation.guests'), value: 'guests', sortable: true },
          {
            text: this.$t('reservation.note'),
            align: 'start',
            sortable: true,
            value: 'note',
            width: '25%',
          }
        ];
      },
      reservations() {
        return this.$store.state.reservations.reservations;
      },
    },
    methods: {
      dayjs,
      rowClick(item) {
        this.$store.dispatch('reservations/selectReservation', item);
      },
      itemBackgroundColor(item) {
        return {
          'blue lighten-5': ['confirmed', 'checked_in'].includes(item.status) && dayjs().isBetween(dayjs(item.startsAt), dayjs(item.endsAt)),
        };
      },
      getRoom(roomId) {
        return this.$store.getters['authentication/getRoomById'](roomId);
      },
      sortRoom(a, b) {
        return this.getRoom(a).title.localeCompare(this.getRoom(b).title);
      },
    },
    watch: {
      filters: {
        handler() {
          clearTimeout(searchTimer);
          searchTimer = setTimeout(() => {
            this.$store.dispatch('reservations/initialize', { query: this.filters });
          }, 1000);
        },
        deep: true,
      },
    },
    mounted() {
      this.$store.dispatch('reservations/initialize', { query: this.filters });
    },
  };
</script>
