<template>
  <v-container
    class="person-container"
    fluid
  >
    <v-icon :color="iconColor">mdi-set-center</v-icon>
    <div class="person-autocomplete rounded-t" :class="{errors: hasErrors}">
      <v-autocomplete
        :filter="()=>true"
        :items="peopleSearchItems"
        :label="label"
        :placeholder="label"
        :readonly="readonly"
        :search-input.sync="peopleSearch"
        class="ma-0"
        hide-no-data
        hide-selected
        item-text="name"
        item-value="id"
        return-object
        :error-messages="errors.people"
        v-model="person"
        @input="show"
      >
        <template v-slot:label>
          <span :class="{'error--text': hasErrors}">{{ label }}</span>
        </template>
        <template v-slot:item="{ item }">
          <person-list-item
            :item="item"
            @click="peopleSearch = ''"
          ></person-list-item>
        </template>
        <template v-slot:append-outer>
          <v-btn
            @click="addNewGuest"
            v-t="'contact.new'"
          ></v-btn>
        </template>
        <template v-slot:selection="{ item }">
          <v-list-item
            three-line
            class="pa-0"
          >
            <v-list-item-content class="pa-0">
              <v-list-item-title>
                <div
                  :class="{'person-name': isReservation}"
                  @click="selectPerson(item)"
                  style="cursor: pointer"
                >
                  <span>{{item.name}}</span>
                  <br>
                  <span class="grey--text" v-if="item.address">{{item.address}}</span>
                </div>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon @click="removePerson">mdi-close</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
      </v-autocomplete>

    </div>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import PersonListItem from '@/components/shared/PersonListItem';
  import { guestDataFor } from '@/util/guest';

  export default {
    components: {
      PersonListItem,
    },
    props: ['value', 'label', 'existing', 'type', 'readonly', 'errors'],
    data() {
      return {
        person: this.value,
        peopleSearchItems: [{ ...this.value }],
        peopleSearch: this.value.name ?? '',
      };
    },
    computed: {
      isReservation() {
        return this.type === 'Reservation';
      },
      user() {
        return this.$store.state.authentication;
      },
      personErrors() {
        const keys = Object.keys(this.errors).filter(key => key.startsWith('people['));
        const errors = {};
        keys.forEach(key => {
          // eslint-disable-next-line no-unused-vars
          const [_, id, attribute] = key.match(/people\[(\d+)]\.([^.]*)/);
          errors[id] = errors[id] || {};
          errors[id][attribute] = [...errors[id][attribute] || [], ...this.errors[key]];
        });

        return errors;
      },
      hasErrors() {
        return Object.keys(this.personErrors).length > 0;
      },
      iconColor() {
        return this.hasErrors || !!this.errors.people ? 'red' : '';
      },
    },
    methods: {
      show(person) {
        this.peopleSearch = person.name ?? '';
      },
      addNewGuest() {
        this.$store.dispatch('contacts/selectPerson', {
          name: this.peopleSearch,
          addToOrder: true,
        });
      },
      removePerson() {
        this.person = null;
        this.peopleSearch = '';
      },
      selectPerson(item) {
        if (!this.existing) {
          return;
        }
        this.$store.dispatch('contacts/selectPerson', item);
      },
      search(value) {
        const data = {
          query: {
            search: value,
            limit: 50,
            exclude_ids: [this.person?.id ?? 0]
          }
        };

        return axios
          .post(this.user.resources.queries.people.url, data)
          .then(response => {
            this.peopleSearchItems = [...response.data.results.map(person => guestDataFor(person))];
          });
      },
    },
    watch: {
      value() {
        this.person = this.value;
        this.peopleSearchItems = [this.value];
      },
      peopleSearch(value, oldValue) {
        if (value === null) {
          return;
        }
        clearTimeout(this._timerId);
        this._timerId = setTimeout(() => this.search(value), 500);
      },
      person() {
        if (JSON.stringify(this.person) !== JSON.stringify(this.value)) {
          this.$emit('input', this.person);
        }
      }
    },
  };
</script>

<style lang="scss">
@import 'vuetify/src/styles/styles.sass';

.person-container {
  display: flex;
  align-items: flex-start;
  padding: 0;

  > .v-icon {
    margin-right: 9px;
    margin-top: 17px;
  }

  .person-list {
    &.big {
      .v-list-item__title {
        display: flex;
        .person-name {
          width: 75%;
          overflow-x: hidden;
        }
        .v-select {
          width: 25%;
        }
      }
    }
  }
}

.person-autocomplete {
  background: rgba(0, 0, 0, .06);
  display: inline-grid;
  border-bottom: 1px solid black;
  padding: 12px;
  width: 100%;

  &.errors {
    border-bottom: 1px solid map-deep-get($red, 'base');
  }

  label {
    transform: translateY(-18px) scale(.75);
  }

  .v-list-item {
    width: 100%;
  }

  .v-list {
    background: rgba(0, 0, 0, 0);
    //min-height: 11rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .v-select__slot {
    background: rgba(0, 0, 0, 0.0);
    max-height: 400px;

    .v-select__selections {
      input {
        min-height: 20px;
        display: block;
        width: 100%;
        height: 32px;
      }
    }
  }
}
</style>
