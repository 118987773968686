import axios from 'axios';
import { router } from '../../routes/router';
import { guestDataFor } from '../../util/guest';
import { eventFactory } from '../../util/event';
import { paginatedFetch } from '@/util/paginatedFetch';

const state = {
  events: [],
  eventsRequest: false,
  selectedEvent: null,
  eventErrors: {},
  eventRequest: false,
};

const getters = {};

const mutations = {
  setEvents: (state, payload) => {
    state.events = payload;
  },
  selectEvent: (state, payload) => {
    state.selectedEvent = { timestamp: new Date(), ...payload };
  },
  setEventErrors: (state, payload) => {
    state.eventErrors = payload;
  },
  addContactToEvent: (state, payload) => {
    if (state.selectedEvent) {
      state.selectedEvent.guests.push(guestDataFor(payload));
    }
  },
  setEventRequest: (state, payload) => {
    state.eventRequest = payload;
  },
  setEventsRequest: (state, payload) => {
    state.eventsRequest = payload;
  },
};

const actions = {
  initialize: async ({ commit, rootState, state }, { query }) => {
    commit('setEvents', []);
    commit('setEventsRequest', true);
    const user = rootState.authentication;

    const data = { query };
    await paginatedFetch(
      user.resources.queries.events.url,
      data,
      (results) => commit('setEvents', state.events.concat(results.map(eventFactory))),
      () => commit('setEventsRequest', false),
      1
    );
  },
  newEvent: ({ dispatch }, { startsAt }) => {
    return dispatch('selectEvent', { guests: [], title: '', startsAt });
  },
  selectEvent: ({ commit, rootState }, payload) => {
    const { company } = rootState.authentication;
    commit('selectEvent', null);
    if (payload.url) {
      commit('setEventRequest', true);
      axios
        .get(payload.url)
        .then(response => commit('selectEvent', eventFactory(response.data)))
        .catch(() => commit('addMessage', { message: 'messages.something_went_wrong', type: 'warning' }, { root: true }))
        .finally(() => commit('setEventRequest', false));
    } else {
      const { year, month } = router.currentRoute.params;
      const startsAt = payload?.startsAt ?? (year ? new Date(parseInt(year), parseInt(month) - 1, 1) : new Date());

      const eventStart = company.config.event.start.split(':');
      startsAt.setHours(parseInt(eventStart[0]));
      startsAt.setMinutes(parseInt(eventStart[1]));
      setTimeout(() => commit('selectEvent', eventFactory({ ...payload, starts_at: startsAt.toISOString() })), 1);
    }
  },
  saveEvent: ({ commit, dispatch, rootState }, payload) => {
    const user = rootState.authentication;
    const url = payload.url ? payload.url : user.company.resources.events.url;
    const method = payload.url ? 'patch' : 'post';
    commit('setEventRequest', true);
    return axios[method](url, payload.data)
      .then(response => {
        commit('setEventErrors', []);
        dispatch('calendar/reinit', null, { root: true });
        commit('addMessage', { message: 'event.save_success', type: 'success' }, { root: true });
        setTimeout(() => commit('selectEvent', eventFactory(response.data)), 1000);
      })
      .catch(response => commit('setEventErrors', response.response.data.errors))
      .finally(() => commit('setEventRequest', false));
  },
  deleteEvent: ({ commit, dispatch }, { url }) =>
    axios.delete(url)
      .then(() => {
        commit('selectEvent', null);
        dispatch('calendar/reinit', null, { root: true });
        commit('addMessage', { message: 'event.delete_success', type: 'success' }, { root: true });
      }),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
