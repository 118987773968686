<template>
  <v-select
    :prepend-icon="prependIcon"
    :items="translatedItems"
    :label="label"
    :readonly="readonly"
    :multiple="multiple"
    filled
    dense
    v-model="item"
    :error-messages="errorMessages"
    :hide-details="!!hideDetails"
    ref="select"
    @blur="$refs.select.blur()"
    @change="closeDebounce"
  >
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" :three-line="hasDescription">
        <v-list-item-content>
          <v-list-item-title>{{ getTitle(item.value) }}</v-list-item-title>
          <v-list-item-subtitle
            v-if="hasDescription"
          >{{ getDescription(item.value) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-slot:append v-if="multiple">
      <v-icon @click="item = []; $refs.select.blur()">mdi-close</v-icon>
    </template>
  </v-select>
</template>

<script>
  import translatedList from '../../mixins/translated_list';

  let closeTimer = null;

  export default {
    mixins: [translatedList],
    slots: ['append'],
    props: {
      label: {
        type: String,
        default: '',
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      value: {
        type: [String, Array],
      },
      prefix: {
        type: String,
        default: '',
      },
      postfix: {
        type: String,
        default: '',
      },
      items: {
        type: Array,
        default: () => [],
      },
      prependIcon: {
        type: String,
        default: '',
      },
      hasDescription: {
        type: Boolean,
        default: false,
      },
      errorMessages: {
        type: [Array, String],
        default: () => [],
      },
      hideDetails: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        item: this.value,
      };
    },
    computed: {
      translatedItems() {
        return this.translatedListFor(this.prefix, this.items, this.postfix);
      }
    },
    methods: {
      getDescription(item) {
        return this.getTitle(String(item), 'description');
      },
      getTitle(item, postfix) {
        return this.$t(this.join(this.prefix, String(item), postfix || this.postfix));
      },
      closeDebounce() {
        if (closeTimer) {
          clearTimeout(closeTimer);
        }
        closeTimer = setTimeout(() => {
          this.$refs.select.blur();
        }, 1000);
      },
    },
    watch: {
      item() {
        this.$emit('input', this.item);
      },
      value() {
        this.item = this.value;
      },
    }
  };
</script>
