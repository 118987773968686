<template>
  <dialog-container
    v-model="open"
    :close="reallyClose"
    :item="person"
    :title="name"
    :dirty="dirty"
    type="person"
    ref="dialog"
    klass="light-blue"
  >
    <template v-slot:body>
      <v-container fluid class="px-0">
        <v-row no-gutters>
          <v-col cols="12" lg="8" md="8" sm="12" class="pa-0">
            <v-text-field
              :error-messages="personErrors.name"
              :label="$t('contact.name')"
              :placeholder="$t('contact.name')"
              :readonly="readonly"
              filled
              v-model="name"
              prepend-icon="mdi-account"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12" class="pa-0">
            <country-select
              :label="$t('contact.citizenship_country_code')"
              v-model="citizenshipCountryCode"
              :errors="personErrors.citizenship_country_code"
            ></country-select>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12" class="pa-0">
            <person-contact-infos
              :error-messages="personErrors.emails"
              type="email"
              v-model="emails"
            ></person-contact-infos>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12" class="pa-0">
            <person-contact-infos
              :error-messages="personErrors.phones"
              type="phone"
              v-model="phones"
            ></person-contact-infos>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12" class="pa-0">
            <country-select
              :label="$t('contact.residence_country_code')"
              v-model="residenceCountryCode"
              :errors="personErrors.residence_country_code"
            ></country-select>
          </v-col>
          <v-col cols="12" lg="3" md="3" sm="12" class="pa-0">
            <v-text-field
              :error-messages="personErrors.zipcode"
              :label="$t('contact.zipcode')"
              :placeholder="$t('contact.zipcode')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-postage-stamp"
              v-model="zipcode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="5" md="5" sm="12" class="pa-0">
            <v-text-field
              :error-messages="personErrors.address"
              :label="$t('contact.address')"
              :placeholder="$t('contact.address')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-map-marker"
              v-model="address"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3" md="6" sm="12" class="pa-0">
            <date-picker
              :error-messages="personErrors.birthdate"
              :label="$t('contact.birthdate')"
              :placeholder="$t('contact.birthdate')"
              :readonly="readonly"
              filled
              icon="mdi-cake-variant"
              v-model="birthdate"
            ></date-picker>
          </v-col>
          <v-col cols="12" lg="3" md="6" sm="12" class="pa-0">
            <v-text-field
              :error-messages="personErrors.birthplace"
              :label="$t('contact.birthplace')"
              :placeholder="$t('contact.birthplace')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-city"
              v-model="birthplace"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3" md="6" sm="12" class="pa-0">
            <translated-select
              :error-messages="personErrors.sex"
              :items="$store.state.data.sexes"
              :label="$t('contact.sex')"
              filled
              prepend-icon="mdi-gender-male-female"
              prefix="contact.sexes"
              v-model="sex"
            ></translated-select>
          </v-col>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-textarea
              :error-messages="personErrors.note"
              :label="$t('contact.note')"
              :readonly="readonly"
              auto-grow
              filled
              prepend-icon="mdi-text"
              v-model="note"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <person-dialog-items
              :items="reservations"
              :person="person"
              type="reservation"
            ></person-dialog-items>
          </v-col>
          <v-col>
            <person-dialog-items
              :items="events"
              :person="person"
              type="event"
            ></person-dialog-items>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:actions>

      <v-spacer></v-spacer>
      <v-btn
        @click="save"
        color="green darken-1"
        text
        v-if="!readonly"
        v-t="'contact.save'"
        :disabled="!enabled || !dirty"
      ></v-btn>

      <v-btn
        @click="remove"
        color="red darken-1"
        text
        v-if="person?.url && !readonly"
        v-t="'contact.delete'"
        :disabled="!enabled"
      ></v-btn>
      <v-btn
        @click="$refs.dialog && $refs.dialog.dialogClose()"
        color="yellow darken-1"
        text
        v-t="'contact.close'"
      ></v-btn>
    </template>
  </dialog-container>
</template>

<script>
  import DialogContainer from '../shared/DialogContainer';
  import PersonDialogItems from './PersonDialogItems';
  import PersonContactInfos from './PersonContactInfos';
  import CountrySelect from './CountrySelect';
  import TranslatedSelect from '../shared/TranslatedSelect';
  import DatePicker from '../shared/pickers/DatePicker';
  import { compareArrays } from '../../util/comparators';

  export default {
    components: {
      CountrySelect,
      DatePicker,
      DialogContainer,
      PersonDialogItems,
      PersonContactInfos,
      TranslatedSelect,
    },
    data() {
      return {
        open: false,
        name: '',
        emails: [],
        address: '',
        note: '',
        phones: [],
        birthdate: '',
        birthplace: '',
        residenceCountryCode: '',
        citizenshipCountryCode: '',
        sex: '',
        zipcode: '',
        reservations: [],
        events: [],
      };
    },
    computed: {
      dirty() {
        const person = this.person || {};
        return !(this.name === person.name &&
          this.address === person.address &&
          this.note === person.note &&
          this.birthplace === person.birthplace &&
          this.birthdate === person.birthdate &&
          this.residenceCountryCode === person.residence_country_code &&
          this.citizenshipCountryCode === person.citizenship_country_code &&
          this.sex === person.sex &&
          this.zipcode === person.zipcode &&
          compareArrays(this.emails, person.emails) &&
          compareArrays(this.phones, person.phones)
        );
      },
      person() {
        return this.$store.state.contacts.selectedPerson;
      },
      enabled() {
        return this.$store.state.contacts.enabled;
      },
      personErrors() {
        return this.$store.state.contacts.personErrors;
      },
      readonly() {
        if (!this.person) {
          return;
        }
        return this.person.url && (this.person.auth || []).indexOf('update') === -1;
      },
      company() {
        return this.$store.state.authentication.company;
      }
    },
    methods: {
      reallyClose() {
        this.$store.commit('contacts/selectPerson', null);
        this.open = false;
      },
      save() {
        this.$store.dispatch('contacts/savePerson', {
          url: this.person.url,
          addToReservation: !!this.person.addToReservation,
          addToEvent: !!this.person.addToEvent,
          addToOrder: !!this.person.addToOrder,
          data: {
            name: this.name,
            phones: this.phones,
            emails: this.emails,
            note: this.note,
            address: this.address,
            birthdate: this.birthdate,
            birthplace: this.birthplace,
            residence_country_code: this.residenceCountryCode,
            citizenship_country_code: this.citizenshipCountryCode,
            sex: this.sex,
            zipcode: this.zipcode,
          },
        });
      },
      remove() {
        this.$confirm(`${this.$t('contact.confirm_delete')}<br/>${this.name}`)
          .then(response => {
            if (response) {
              this.$store.dispatch('contacts/deletePerson', {
                url: this.person.url,
              });
            }
          });
      },
    },
    watch: {
      person() {
        this.$store.commit('contacts/setPersonErrors', []);
        if (!this.person) {
          this.open = false;
          this.phones = null;
          return;
        }

        this.open = true;
        this.name = this.person.name;
        this.emails = (this.person.emails || []).slice(0);
        this.address = this.person.address;
        this.note = this.person.note;
        this.phones = (this.person.phones || []).slice(0);
        this.birthdate = this.person.birthdate;
        this.birthplace = this.person.birthplace;
        this.residenceCountryCode = this.person.residence_country_code;
        this.citizenshipCountryCode = this.person.citizenship_country_code;
        this.sex = this.person.sex;
        this.zipcode = this.person.zipcode;
        this.reservations = this.person.reservations;
        this.events = this.person.events;
      },
    },
  };
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
