<template>
  <tr>
    <td class="pa-1 subtitle-2 text-right room-title">E</td>
    <td class="pa-1 room-icon text-center">
      <v-icon small>mdi-calendar-clock</v-icon>
    </td>
    <td :class="{[day.day]: true, clickable: !filterEvents(day.date.getDate()).length }" class="event" :key="day.title" v-for="day in days" @click="cellClick(day.date)">
      <event-button
        :date="day.date"
        :events="filterEvents(day.date.getDate())"
        v-if="filterEvents(day.date.getDate()).length"
      ></event-button>
    </td>
    <td class="pa-1 room-icon text-center">
      <v-icon small>mdi-calendar-clock</v-icon>
    </td>
    <td class="pa-1 subtitle-2 text-left room-title">E</td>
  </tr>
</template>

<script>
  import EventButton from './EventButton';

  export default {
    components: {
      EventButton,
    },
    props: ['days'],
    computed: {
      events() {
        return this.$store.state.events.events;
      },
    },
    methods: {
      filterEvents(day) {
        return this.events.filter(event => event.startsAt.getDate() === day);
      },
      cellClick(date) {
        if (this.filterEvents(date.getDate()).length) {
          return;
        }

        this.$store.dispatch('events/newEvent', { startsAt: date });
      },
    },

  };
</script>

<style>
td.clickable {
  cursor: cell;
}
</style>
