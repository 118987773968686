<template>
  <picker-container
    :prepend-icon="icon"
    v-model="item"
    :label="label"
    :error-messages="errorMessages"
    :placeholder="placeholder"
    :readonly="readonly"
    hint="YYYY-MM-DD"
    ref="picker"
  >
    <template v-slot:picker>
      <v-date-picker
        v-model="pickerItem"
        no-title
        scrollable
        :locale="locale"
        min="1900-01"
        max="2100-01"
        @input="click"
      ></v-date-picker>
    </template>
    <template v-slot:append>
      <v-icon @click="item = null">mdi-close</v-icon>
    </template>
  </picker-container>
</template>

<script>
  import dayjs from 'dayjs';
  import PickerContainer from './PickerContainer';

  export default {
    components: {
      PickerContainer,
    },
    props: ['errorMessages', 'label', 'placeholder', 'readonly', 'value', 'icon'],
    data() {
      return {
        menu: false,
        item: this.value,
        pickerItem: this.value,
      };
    },
    computed: {
      locale() {
        return this.$store.state.authentication.company.locale;
      },
    },
    methods: {
      click() {
        this.item = this.pickerItem;
      },
    },
    watch: {
      value() {
        this.item = this.value;
      },
      item() {
        const date = dayjs(this.item, 'YYYY-MM-DD');
        if (this.item === null) {
          this.$emit('input', this.item);
        }

        if (!date.isValid()) {
          return;
        }
        this.pickerItem = date.format('YYYY-MM-DD');
        this.$refs.picker.save(this.item);
        this.$emit('input', this.item);
        this.menu = false;
      }
    }
  };
</script>
