<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          :label="$t('contact.search')"
          filled
          icon="mdi-magnify"
          v-model="filters.search"
          ref="select"
          prepend-icon="mdi-text-search"
        >
          <template v-slot:append>
            <v-icon @click="filters.search = ''; $refs.select.blur()">mdi-close</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </v-container>
</template>

<script>
  let closeTimer = null;

  export default {
    props: {
      value: {
        type: Object,
      },
    },
    data() {
      return {
        filters: this.value,
      };
    },
    computed: {
      rooms() {
        return this.$store.getters['authentication/getRoomsWithHeaders'].map(room => ({ ...room }));
      },
      calendars() {
        return this.$store.state.authentication.company.calendars.map(calendar => ({ text: calendar.title, value: calendar.id }));
      },
    },
    methods: {
      closeDebounce() {
        if (closeTimer) {
          clearTimeout(closeTimer);
        }
        closeTimer = setTimeout(() => this.$refs.select.blur(), 1000);
      },
    },
  };
</script>
