<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <navigational-buttons
          :year="year"
          :month="month"
          type="room"
        ></navigational-buttons>
      </v-col>
      <v-col cols="12">
        <v-sheet height="500">
          <v-calendar
            color="primary"
            ref="calendar"
            v-model="focus"
            :events="events"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="addReservation"
            @change="updateRange"
            :locale="locale"
            :event-more="false"
          >
            <template v-slot:event="{ event }">
              <reservation-chip
                :month="month"
                :reservation="event"
              ></reservation-chip>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import dayjs from 'dayjs';
  import reservationMixin from '../../mixins/reservations';
  import NavigationalButtons from '../shared/navigation/NavigationalButtons';
  import ReservationChip from '../calendar/ReservationChip';
  import { router } from '../../routes/router';

  export default {
    components: {
      NavigationalButtons,
      ReservationChip,
    },
    mixins: [
      reservationMixin
    ],
    data() {
      const { year, month, id } = this.$route.params;
      return {
        year: parseInt(year),
        month: parseInt(month),
        id,
        focus: `${year}-${month}-01`,
        type: 'month',
      };
    },
    computed: {
      locale() {
        return this.$store.state.authentication.company.locale;
      },
      events() {
        return this.$store.state.room.reservations
          .map(reservation => ({
            start: reservation.startsAt,
            end: dayjs(reservation.endsAt).subtract(1, 'day').toDate(),
            color: this.reservationColor(reservation),
            name: this.reservationTitle(reservation),
            ...reservation
          }));
      }
    },
    methods: {
      showEvent({ event }) {
        this.$store.dispatch('reservations/selectReservation', event);
      },
      viewDay({ date }) {
        this.focus = date;
      },
      updateRange({ start }) {
        const date = dayjs(start.date);
        if (this.focus === `${date.year()}-${date.month() + 1}-01`) {
          return;
        }
        router.push(`/rooms/${this.id}/${date.year()}/${date.month() + 1}`);
      },
      addReservation({ date, month }) {
        if (month !== this.month) {
          return;
        }

        this.$store.dispatch('reservations/newReservation', { startsAt: date(date).toDate(), roomId: parseInt(this.id) });
      },
    },
    watch: {
      '$route'(to) {
        const { year, month, id } = to.params;
        this.year = parseInt(year);
        this.month = parseInt(month);
        this.id = id;

        this.focus = `${this.year}-${this.month}-01`;
        this.$store.dispatch('room/initialize', to.params);
      },
    },
    mounted() {
      this.$store.dispatch('room/initialize', this.$route.params);
    }
  };
</script>
