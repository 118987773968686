<template>
  <v-data-table
    :disable-pagination="true"
    disable-sort
    :headers="headers"
    :hide-default-footer="true"
    :items="orders"
    :search="search"
    @click:row="rowClick"
    calculate-widths
    class="fill-height"
    fixed-header
    sort-by="name"
    :custom-filter="advancedSearch"
  >
    <template v-slot:top>
      <v-text-field
        v-model="search"
        :label="$t('order.search')"
        class="mx-4"
      ></v-text-field>
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ dayjs(item.created_at).format('YYYY-MM-DD HH:mm') }}
    </template>
  </v-data-table>
</template>

<script>
  import dayjs from 'dayjs';

  export default {
    data: () => ({
      dialog: false,
      search: '',
    }),
    computed: {
      headers() {
        return [
          { text: this.$t('order.status'), value: 'status' },
          { text: this.$t('order.created_at'), value: 'created_at' },
          { text: this.$t('order.person'), value: 'person.name' },
          {
            text: this.$t('order.note'),
            align: 'start',
            sortable: true,
            value: 'note',
            width: '25%',
          }
        ];
      },
      orders() {
        return this.$store.state.orders.orders;
      },
    },
    methods: {
      dayjs,
      rowClick(item) {
        this.$store.dispatch('orders/selectOrder', item);
      },
      advancedSearch(value, search, item) {
        return value !== null &&
          search !== null &&
          typeof value === 'string' &&
          value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase()
            .indexOf(search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()) > -1;
      },
    },
    mounted() {
      this.$store.dispatch('orders/initialize');
    },
  };
</script>
