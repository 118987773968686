import { router } from '@/routes/router';
import { paginatedFetch } from '@/util/paginatedFetch';
import { reservationFactory } from '@/util/reservation';
import { eventFactory } from '@/util/event';

const state = {
};

const getters = {};

const mutations = {
};

const actions = {
  initialize: async ({ commit, rootGetters, rootState, state }, { year, month, id }) => {
    commit('reservations/setReservations', [], { root: true });
    commit('reservations/setReservationsRequest', true, { root: true });
    commit('events/setEventsRequest', true, { root: true });
    commit('events/setEvents', [], { root: true });
    const user = rootState.authentication;

    const from = new Date(year, parseInt(month) - 1);
    const to = new Date(year, month);
    const roomIds = rootGetters['authentication/getRoomsFor'](parseInt(id)).map(room => room.id);
    const data = { query: { room_id: roomIds, from_date: from, to_date: to, statuses: rootState.data.liveReservationStatuses } };
    await paginatedFetch(
      user.resources.queries.reservations.url,
      data,
      (results) => commit('reservations/setReservations', rootState.reservations.reservations.concat(results.map(reservationFactory)), { root: true }),
      () => commit('reservations/setReservationsRequest', false, { root: true })
    );
    await paginatedFetch(
      user.resources.queries.events.url,
      data,
      (results) => commit('events/setEvents', rootState.events.events.concat(results.map(eventFactory)), { root: true }),
      () => commit('events/setEventsRequest', false, { root: true })
    );
  },
  reinit: ({ dispatch }) => {
    if (router.currentRoute.name === 'calendar') {
      const { year, month, id } = router.currentRoute.params;
      dispatch('initialize', { year, month, id });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
