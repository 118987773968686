<template>
  <v-app v-if="userStatus === 'loading'">
    <v-main>
      <v-container fluid>
        <v-row class="fill-height">
          <v-col
            cols="12"
            class="justify-center d-flex"
          >
            <v-progress-circular
              :width="7"
              :size="70"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
  <v-app v-else-if="userStatus === 'loggedIn'">
    <drawer v-model="drawer"></drawer>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
    >
      <v-app-bar-nav-icon
        dark
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        class="ml-0 pl-4 hidden-sm-and-down"
        style="width: 300px"
      >
        <router-link
          style="text-decoration: none; color: white"
          :to="{name: 'dashboard'}"
        >
          {{ softwareTitle }}
        </router-link>
      </v-toolbar-title>
      <search :key="$route.fullPath"></search>
      <v-spacer></v-spacer>
      <v-btn icon dark>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <account-dropdown v-if="email"></account-dropdown>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <progress-bar></progress-bar>
        <router-view></router-view>
        <reservation-dialog></reservation-dialog>
        <person-dialog></person-dialog>
        <event-dialog></event-dialog>
        <order-dialog></order-dialog>
        <settings-account-dialog></settings-account-dialog>
        <speed-dial></speed-dial>
      </v-container>
    </v-main>
  </v-app>
  <v-app v-else>
    <v-main>
      <welcome></welcome>
    </v-main>
  </v-app>
</template>

<script>
  import ReservationDialog from '../reservations/ReservationDialog';
  import PersonDialog from '../contacts/PersonDialog';
  import EventDialog from '../events/EventDialog';
  import OrderDialog from '../orders/OrderDialog';
  import AccountDropdown from '../account/AccountDropdown';
  import SettingsAccountDialog from '../settings/accounts/SettingsAccountDialog';
  import Drawer from './Drawer';
  import Search from './Search';
  import SpeedDial from './SpeedDial';
  import Welcome from '../welcome/Welcome';
  import ProgressBar from './ProgressBar';
  import software from '../../mixins/software';

  export default {
    props: {
      source: String,
    },
    mixins: [
      software
    ],
    components: {
      ReservationDialog,
      PersonDialog,
      EventDialog,
      OrderDialog,
      AccountDropdown,
      SettingsAccountDialog,
      Drawer,
      Search,
      SpeedDial,
      Welcome,
      ProgressBar,
    },
    data: () => ({
      drawer: localStorage.getItem('drawer') === 'true',
      userStatus: 'loading',
    }),
    computed: {
      email() {
        return this.$store.state.authentication.email;
      },
      message() {
        return this.$store.state.message;
      }
    },
    methods: {
      handleRouteChange({ name, params }) {
        let title = '';
        if (this.userStatus === 'loggedIn') {
          const id = parseInt(params.id);
          switch (name) {
            case 'calendar':
              title = this.$store.getters['authentication/getCalendarById'](id).title + ' - ' + this.$t('menu.calendars');
              break;
            case 'room':
              // eslint-disable-next-line no-case-declarations
              const room = this.$store.getters['authentication/getRoomById'](id);
              title = room.calendar.title + ' - ' + room.title + ' - ' + this.$t('menu.room');
              break;
            default:
              title = this.$t(`menu.${name || 'dashboard'}`);
          }
        }

        document.title = title + ' - ' + this.softwareTitle;
      },
    },
    watch: {
      message() {
        const { message, type } = this.message;
        this.$toast[type](this.$t(message), { queueable: true });
      },
      email() {
        if (!this.email) {
          this.userStatus = 'loggedOut';
        }
      },
      drawer() {
        localStorage.setItem('drawer', this.drawer);
      },
      $route: {
        handler({ name, params }) {
          this.handleRouteChange({ name, params });
        },
      },
    },
    mounted() {
      // enable using draggable dialogs
      this.activateMultipleDraggableDialogs();
    },
    async beforeCreate() {
      try {
        await this.$store.dispatch('authentication/initialize');
        await this.$store.dispatch('data/initialize');
        await this.$store.dispatch('initializeCountries');
        this.userStatus = 'loggedIn';
        this.$recaptchaInstance?.hideBadge();
        this.handleRouteChange(this.$route);
      } catch (error) {
        this.userStatus = 'loggedOut';
        this.$recaptchaInstance?.showBadge();
        console.info('not logged in');
      }
    },
  };
</script>
