import axios from 'axios';

const controllers = {};

export async function paginatedFetch(url, params, setFn, endFn = () => {}, page = 1, nestedResources = undefined) {
  controllers[url]?.abort();
  controllers[url] = new AbortController();

  const headers = {};

  if (nestedResources) {
    headers['X-IK-Nested-Resources'] = JSON.stringify(nestedResources);
  }

  return axios
    .post(url, { query: { ...params.query || {}, page } }, { signal: controllers[url].signal, headers })
    .then(async response => {
      const { data } = response;
      setFn(data.results);
      if (data.results.length === data.page_size) {
        await paginatedFetch(url, params, setFn, endFn, page + 1, nestedResources);
      } else {
        endFn();
      }
    })
    .catch(error => axios.isCancel(error) || console.log(error));
}
